
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";

import ApiService from "@/core/services/ApiService";
import store from "@/store";
import axios from "axios";

export default defineComponent({
  name: "test",
  
  data () {
    return {
      html: "",
      username: "+ho",
      password: "333666"
    }
  },

  mounted () {
    //this.submit()
  },
  methods: {
    submit () {
      axios
        .post(
          process.env.VUE_APP_API_URL +"&mode=pub&tab=test&type=loginTest",
          JSON.stringify({
            username: encodeURI(this.username),
            password: this.password,
          })
        )
        .then((response) => {
          console.log(response.data)
          console.log(encodeURI(this.username))
        })
        .catch((e) => {
          console.log(e)
        });
    },
    getData() {
      ApiService.post(
        process.env.VUE_APP_API_URL +
          "&mode=pub&tab=test&type=loginTest", {}
      ).then((response) => {
        this.html = (response.data)
      });
    },
  },
  
});
